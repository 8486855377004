import { IsWidget, Widget } from './widget';
import { IsLayout, Layout } from './layout';

export interface Dashboard {
  uuid?: string;
  name: string | null;
  companyUuid: string | null;
  locationUuid: string | null;
  widgetList: Widget[];
  layoutList: Layout[];
}

const assertType = <T>(predicate: (item: any) => item is T, array: any[]): array is T[] => {
  return Array.isArray(array) && array.every(predicate);
}

// eslint-disable-next-line complexity
export function IsDashboard(obj: any): obj is Dashboard {
  return obj !== null
    && obj !== undefined
    && typeof obj === 'object'
    && (obj.name === null || typeof obj.name === 'string')
    && assertType(IsWidget, obj.widgetList)
    // && assertType(IsLayout, obj.layoutList);
    // TODO : check all dashboard layouts
    && obj.layoutList[0] && IsLayout(obj.layoutList[0]);
}
