import { ChangeDetectionStrategy, Component, Inject, LOCALE_ID } from '@angular/core';
import { Environment, RXAP_ENVIRONMENT } from '@rxap/environment';
import { NgIf } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ConfigService } from '@rxap/config';
import { KeycloakService } from '@rxap/keycloak';
import { StatusIndicatorComponent } from '@rxap/ngx-status-check';

@Component({
  selector: 'eurogard-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,

  standalone: true,
  imports: [ RouterOutlet, NgIf, StatusIndicatorComponent ],
})
export class AppComponent {

  constructor(
    @Inject(RXAP_ENVIRONMENT)
    private readonly environment: Environment,
    @Inject(LOCALE_ID) localId: string,
    titleService: Title,
    config: ConfigService<any>,
    @Inject(KeycloakService)
    protected readonly keycloak: KeycloakService,
  ) {
    console.log('local', localId);
    if (config.get('app.name', null)) {
      titleService.setTitle(config.getOrThrow('app.name'));
    }
  }

  public get release(): string | undefined | null {
    return this.environment.release;
  }

}
