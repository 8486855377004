import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Inject, Injectable, isDevMode } from '@angular/core';
import { RxapAuthenticationService } from '@rxap/authentication';
import { KeycloakService } from '@rxap/keycloak';
import { EurogardAuthenticationService } from './eurogard-authentication.service';

@Injectable({providedIn: 'root'})
export class PermissionGuard {

  constructor(
    private readonly router: Router,
    @Inject(KeycloakService)
    private readonly keycloakService: KeycloakService,
    @Inject(RxapAuthenticationService)
    private readonly authenticationService: EurogardAuthenticationService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    return this.canActivateChild(route, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const requiredPermissions = route.data['permissions'];

    if (isDevMode()) {
      console.debug('required permissions: ', requiredPermissions);
    }

    if (!(requiredPermissions instanceof Array) || requiredPermissions.length === 0) {
      return true;
    }

    const permissions = this.keycloakService.getUserRoles();

    if (isDevMode()) {
      console.debug('permissions: ', permissions?.sort());
    }

    const hasAccess = requiredPermissions.some((role) => permissions.includes(role));

    if (!hasAccess) {
      if (isDevMode()) {
        console.warn('PermissionGuard :: User does not have the required permissions');
      }
      return this.router.createUrlTree(
        [ '/', 'insufficient-permissions' ],
        {queryParams: {permissions: JSON.stringify(requiredPermissions)}},
      );
    }

    return true;
  }

}
