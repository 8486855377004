import { LayoutTarget } from './layout-target';

export interface Layout {
  uuid?: string;
  xml: string;
  target: LayoutTarget;
  name: string | null;
}

// eslint-disable-next-line complexity
export function IsLayout(item: any): item is Layout {
  return item &&
    typeof item === 'object' &&
    typeof item.xml === 'string' &&
    typeof item.target === 'string' &&
    (item.name === null || typeof item.name === 'string');
}
