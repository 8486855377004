import { Environment } from '@rxap/environment';

export const environment: Environment = {
  name: 'production',
  production: true,
  app: 'dashboard-editor',
  serviceWorker: true,
  sentry: {
    dsn: 'https://7d10b143e3d14bb5b8e0099c1c071416@sentry.eurogard.cloud/5',
    enabled: true,
    debug: false,
  },
};
