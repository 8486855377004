import { BaseWidget } from './base.widget';

export enum WidgetTextAlign {
  CENTER = 'center',
  START = 'start',
  END = 'end',
  JUSTIFY = 'justify'
}

export enum WidgetVerticalAlignment {
  CENTER = 'center',
  START = 'start',
  END = 'end',
}

export interface StaticTextWidgetContent extends BaseWidget {
  value: string;
  color: string | null;
  bold: boolean;
  italic: boolean;
  align: WidgetTextAlign;
  vertical: WidgetVerticalAlignment;
}
