import { BaseWidget } from './widgets/base.widget';
import { DashboardWidgetConfig } from './config';

export interface Widget<Content extends BaseWidget = BaseWidget> extends Omit<DashboardWidgetConfig<Content>, 'component'> {
  uuid?: string;
  internalId: string;
  type: string;
  name: string | null;
  content: Content & Record<string, any>;
  color: string;
}

// eslint-disable-next-line complexity
export function IsWidget(item: any): item is Widget {
  return item &&
    typeof item === 'object' &&
    typeof item.internalId === 'string' &&
    typeof item.type === 'string' &&
    (item.name === null || typeof item.name === 'string') &&
    item.content !== undefined &&
    typeof item.color === 'string';
}
