import { Inject, Injectable } from '@angular/core';
import { RxapAuthenticationService } from '@rxap/authentication';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { KeycloakService } from '@rxap/keycloak';
import { EurogardAuthenticationService } from './eurogard-authentication.service';
import { coerceArray, equals } from '@rxap/utilities';

@Injectable()
export class EurogardAuthorizationService {

  protected readonly permissions$ = new BehaviorSubject<string[]>([]);

  constructor(
    @Inject(KeycloakService)
    private readonly keycloakService: KeycloakService,
    @Inject(RxapAuthenticationService)
    private readonly authenticationService: EurogardAuthenticationService,
  ) {
  }

  checkPermission(identifier: string | string[], permissions: string[], scope?: string | null): boolean {
    identifier = coerceArray(identifier);
    return identifier.some(id => permissions.includes(id));
  }

  hasPermission(identifier: string | string[], scope?: string | null): boolean {
    console.debug('hasPermission', identifier, scope);
    const permissions = this.getPermissions();
    return this.checkPermission(identifier, permissions, scope);
  }

  hasPermission$(identifier: string | string[], scope?: string | null): Observable<boolean> {
    console.debug('hasPermission$', identifier, scope);
    // ensure the permission are loaded
    if (this.permissions$.value.length === 0) {
      this.getPermissions();
    }
    return this.permissions$.pipe(
      map((permissions) =>
        this.checkPermission(identifier, permissions, scope),
      ),
      distinctUntilChanged(),
    );
  }

  getPermissions(): string[] {
    const permissions = this.keycloakService.getUserRoles();
    if (!equals(this.permissions$.value, permissions)) {
      console.debug('permissions', permissions.sort());
      this.permissions$.next(permissions);
    }
    return this.permissions$.value;
  }

  getPermissions$(): Observable<string[]> {
    return this.permissions$;
  }

  setPermissions(permissions: string[]): void {
    throw new Error('The permissions are set by the KeycloakService');
  }


}
